import React, { Component } from 'react'
import {
  AntennaButton,
  Page,
  Spacer,
  TagCounter,
  Box,
  Button,
  OperationReadingCounters,
  OperationReadingList,
} from 'stylewhere/components'
import { DecodedItem, SimpleItemList, BadRequestError } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import {
  Router,
  RemoteOperation,
  SimpleItemListOperationConfig,
  OperationReadingProps,
  OperationReadingState,
  AppStore,
  RfidReader,
  getDataFromSchema,
  OperationReadingProvider,
  FormSchemaData,
} from 'stylewhere/shared'
import { SimpleItemListExtensions } from 'stylewhere/extensions'
import {
  filterIgnoredAndErrorItems,
  showToast,
  showToastError,
  askUserConfirmation,
  isModalError,
} from 'stylewhere/shared/utils'

interface State extends OperationReadingState {
  confirming: boolean
}

export default class SimpleItemListReading<P extends OperationReadingProps<State>, S extends State> extends Component<
  P,
  S
> {
  antennaRef
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<SimpleItemListOperationConfig>(this.matchParams.opCode)
  formSchema = SimpleItemListExtensions.formSchema(this.operation)
  isModal = false

  state: S = {
    items: [],
    loading: true,
    formData: this.locationState.formData ?? {},
    confirming: false,
  } as any

  constructor(props) {
    super(props)
    this.antennaRef = React.createRef()
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)
  }

  getDecodeRequest = () => {
    const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
    decodePayload.operationId = this.operation.id
    return {
      url: SimpleItemList.batchValidateEndpoint(),
      payload: decodePayload,
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, this.getDecodeRequest())
  }

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    const items = await OperationReadingProvider.processDecodedItems(
      this.operation,
      itemMapFromReader,
      this.state.items,
      this.state.formData,
      SimpleItemListExtensions
    )
    this.setState({ items })
  }

  removeItem(decodedItem: DecodedItem) {
    const items = OperationReadingProvider.removeItem(decodedItem, this.state.items)
    this.setState({ items })
  }

  onConfirm = async () => {
    this.setState({ confirming: true }, this._confirm)
  }

  _confirm = async () => {
    const { items, formData } = this.state
    try {
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }
      const confirmData = getDataFromSchema(formData, this.formSchema)
      try {
        await SimpleItemListExtensions.beforeConfirm(this.operation, confirmData, items)
      } catch (error) {
        this.setState({ confirming: false })
        return
      }
      if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing workstation place')
      const confirmResult = await SimpleItemList.save({
        ...confirmData,
        operationId: this.operation.id,
        operationPlaceId: AppStore.defaultWorkstation.placeId,
        itemIds: items.filter(filterIgnoredAndErrorItems).flatMap(({ item }) => (item?.id ? item.id : [])),
      })
      await SimpleItemListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
      this.setState({ confirming: false })
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
      if (['hideSuccessModal', 'disabled'].includes(this.operation.postConfirmAction)) {
        this.goBack()
      } else {
        if (
          await askUserConfirmation(
            __(T.confirm.post_confirm_action_title),
            this.operation.postConfirmAction === 'keepInput'
              ? __(T.confirm.post_confirm_action_keep_input)
              : __(T.confirm.post_confirm_action_change_input),
            __(T.misc.no),
            __(T.misc.yes)
          )
        ) {
          if (this.operation.postConfirmAction === 'keepInput') {
            this.onClear()
          } else {
            this.goBack()
          }
        } else {
          this.goDashboard()
        }
      }
    } catch (err) {
      this.setState({ confirming: false })
      if (err instanceof BadRequestError) {
        showToastError(err, err.title || __(T.error.error), this.isModal)
      } else {
        showToastError(err, __(T.error.error), this.isModal)
      }
    }
  }

  goDashboard = () => {
    Router.navigate('/')
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/simple-item-list/:opCode', { opCode: this.operation.code })
    } else {
      this.goDashboard()
    }
  }

  onClear = () => {
    RfidReader.clear()
    this.setState({ items: [] })
  }

  showConfirmButton() {
    const { items, confirming } = this.state
    if (items.filter(filterIgnoredAndErrorItems).length > 0)
      return <Button loading={confirming} title={__(T.misc.confirm)} onClick={this.onConfirm} />
    return null
  }

  checkChangeData = (schema) => {
    let countDisabled = 0
    let f = 0
    while (f < schema.length) {
      if (schema[f].disableChange) countDisabled++
      f++
    }
    return schema.length > countDisabled
  }

  //by default return empty array : SimpleItemListExtensions.validateForm(data, this.operation). in Extension, change validateForm method with the custom logic
  validateForm = (data: FormSchemaData) => {
    return SimpleItemListExtensions.validateForm(data, this.operation)
  }

  getHeaderDetails = (schema) => {
    const { formData } = this.state
    if (this.checkChangeData(schema)) {
      return {
        details: {
          data: formData,
          formSchema: schema,
          validateForm: this.validateForm,
          setFormData: async (fd) => {
            if (!(await SimpleItemListExtensions.formDataIsValid(fd, this.operation, schema))) return
            this.setState({ formData: fd })
            this.setRfidReaderDecode()
          },
          operationId: this.operation.id,
          resetFormData: async (fd) => {
            this.setState({ formData: fd })
          },
        },
      }
    }
    return {
      details: {
        data: formData,
        formSchema: schema,
      },
    }
  }

  render() {
    const { items, loading } = this.state
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        alertOnBackPress={this.operation.showAlertOnExit && items.length > 0}
        loading={loading}
        header={this.getHeaderDetails(this.formSchema)}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={items.filter(filterIgnoredAndErrorItems).length} />
            <AntennaButton
              ref={this.antennaRef}
              decodeRequest={this.getDecodeRequest()}
              onItemDecoded={this.onDecodedItemCallback}
              onClear={this.onClear}
              hideClear={items.length === 0}
            />
            <Spacer />
            <OperationReadingCounters operation={this.operation} items={items} />
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <OperationReadingList
            removeItemCallback={this.removeItem}
            extension={SimpleItemListExtensions}
            items={items}
            operation={this.operation}
          />
        </Page.Content>
      </Page>
    )
  }
}
