import React, { Component } from 'react'
import { cloneDeep as _cloneDeep } from 'lodash'
import { DecodedItem, Shippings, ShippingParcel, Parcels, Asn } from 'stylewhere/api'
import { T, __, __UP } from 'stylewhere/i18n'
import {
  Router,
  RemoteOperation,
  AppStore,
  OperationReadingState,
  OperationReadingProps,
  checkRequiredField,
  getDataFromSchema,
  RfidReader,
  OperationReadingProvider,
} from 'stylewhere/shared'
import { config } from 'stylewhere/config'
import { ShippingExtensions } from 'stylewhere/extensions'
import { ShippingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import {
  AntennaButton,
  Box,
  Button,
  OperationReadingCounters,
  OperationReadingList,
  Page,
  Spacer,
  TagCounter,
  Stoplight,
  FullLoadingLayer,
  RemoveMode,
} from 'stylewhere/components'
import {
  filterIgnoredAndErrorItems,
  showToast,
  showToastError,
  askUserConfirmation,
  getChecklistItemsCount,
  validateChecklistReadings,
  hasAsyncConfirm,
  RETRY_SSE_TIME,
  getSseEndpoint,
  checkItemsCounterError,
  isModalError,
  hasChecklist,
} from 'stylewhere/shared/utils'
import { Routes } from 'stylewhere/pages'

interface State extends OperationReadingState {
  parcel?: ShippingParcel
  asn?: Asn
  confirming: boolean
  lastAsyncID: string
  sseStatus: boolean
  retrySseCount: number
  backRoute?: Routes
  backRouteParams?: Record<string, string>
  removeModeEnabled: boolean
}

export default class ShippingReading extends Component<OperationReadingProps<State>, State> {
  antennaRef
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(this.matchParams.opCode)
  formSchema = ShippingExtensions.formSchema(this.operation, { parcel: this.locationState.parcel })
  sse: any
  isModal = false
  asn?: Asn = this.locationState.asn

  state: State = {
    items: [],
    loading: true,
    formData: this.locationState.formData ?? {},
    confirming: false,
    lastAsyncID: '',
    sseStatus: false,
    retrySseCount: 0,
    removeModeEnabled: false,
  }

  constructor(props) {
    super(props)
    this.antennaRef = React.createRef()
    this.removeItem = this.removeItem.bind(this)
  }

  isBatchReadingMode = this.operation.persistParcelContentScannings === 'onBatchRead'
  operationRemoveModeAllowed = this.operation.removeMode && ['sku', 'rfid'].includes(this.operation.removeMode)
  isRemoveModeBySku = this.operation.removeMode === 'sku'
  isRemoveModeByRfidRead = this.operation.removeMode === 'rfid'

  async componentDidMount() {
    if (!this.locationState.formData) {
      this.goBack()
      return
    }

    this.isModal = isModalError(this.operation)
    RfidReader.setBatchInterval(this.operation.batchInterval)
    RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
    RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)

    let parcel: ShippingParcel | undefined = this.locationState.parcel
    if (this.isBatchReadingMode) {
      try {
        if (!checkRequiredField(this.state.formData, this.formSchema)) {
          await this.openRequiredFieldsModal()
        }
        const createParcelResponse = await this.createParcel()
        parcel = createParcelResponse?.parcel
        if (!parcel) throw new Error('Missing parcel')
      } catch (error) {
        showToastError(error, __(T.error.error), this.isModal)
      }
    }
    this.setState({ parcel, sseStatus: !hasAsyncConfirm(this.operation) }, this.attachSse)
  }

  componentWillUnmount = () => {
    this.destroySse()
  }

  canConfirm = () => {
    const { items } = this.state

    // ci sono item senza errori e senza ignored ?
    const isItemsAvailable = items.some(filterIgnoredAndErrorItems)
    // ci sono item con errori ?
    const isThereAnyErrorItems = items.some((itm) => itm.item?.status === 'error')

    return isItemsAvailable && !isThereAnyErrorItems
  }

  attachSse = async () => {
    if (!hasAsyncConfirm(this.operation) || !AppStore.loggedUser) {
      return
    }
    const sseUrl = getSseEndpoint(config)
    this.sse = new EventSource(sseUrl, { withCredentials: false })
    this.sse.onerror = () => {
      this.setState({ sseStatus: false, retrySseCount: this.state.retrySseCount + 1 }, this.retrySse)
    }
    this.sse.onopen = (event) => {
      this.setState({ sseStatus: true, retrySseCount: 0 })
    }
    this.sse.onmessage = (event) => {
      this.sseEventMessage(event)
    }
  }

  sseEventMessage = async (event) => {
    const { lastAsyncID } = this.state
    const eventData = event.data ? JSON.parse(event.data) : undefined
    if (eventData && eventData.data) {
      if (eventData.data.asyncExecutionId === lastAsyncID) {
        if (eventData.data.executionStatus === 'OK') {
          try {
            const res = await Parcels.getById(eventData.data.details.operationInstanceId)
            this._confirmSuccess(res)
          } catch (error) {
            this._confirmSuccess()
          }
        } else {
          this._confirmFailed(eventData.data.details || '')
        }
      }
    }
  }

  retrySse = () => {
    this.destroySse()
    if (this.state.retrySseCount === 1) {
      this.attachSse()
    } else {
      setTimeout(() => {
        this.attachSse()
      }, RETRY_SSE_TIME)
    }
  }

  destroySse = () => {
    if (this.sse) {
      this.sse.close()
      this.sse = undefined
    }
  }

  getDecodeRequest = () => {
    const { parcel } = this.state
    const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
    decodePayload.operationId = this.operation.id
    decodePayload.parcelCode = parcel?.code
    if (!decodePayload.clientPlaceId) {
      decodePayload.clientPlaceId = AppStore.defaultWorkstation?.placeId
    }
    return {
      url: Shippings.batchValidateEndpoint(this.operation),
      payload: {
        ...decodePayload,
        asn: undefined,
        // asn: { ...decodePayload.asn, originPlaceId: AppStore.defaultWorkstation?.placeId }
      },
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, this.getDecodeRequest())
  }

  updateParcel = async (action: 'INSERT' | 'UPDATE' | 'CLEAR' | 'REMOVE', itemIds: string[], parcelCode: string) => {
    const parcel = await Shippings.updateParcel(this.operation, {
      operationId: this.operation.id,
      itemIds,
      action,
      parcelCode,
    })
    return parcel
  }

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    const { items, formData, removeModeEnabled } = this.state
    if (removeModeEnabled && this.isRemoveModeByRfidRead) {
      const itemsToRemove = Object.values(itemMapFromReader)
      itemsToRemove.forEach((item) => this.removeItem(item))
    } else {
      const processedItems: DecodedItem[] = await OperationReadingProvider.processDecodedItems(
        this.operation,
        itemMapFromReader,
        items,
        formData,
        ShippingExtensions
      )
      if (this.isBatchReadingMode) {
        if (!this.state.parcel) return
        try {
          await this.updateParcel(
            'INSERT',
            processedItems
              .filter((itm) =>
                Object.values(itemMapFromReader)
                  .map((readerItem) => readerItem.item?.id)
                  .includes(itm.item?.id)
              )
              .filter(filterIgnoredAndErrorItems)
              .flatMap(({ item }) => (item?.id ? item.id : [])),
            this.state.parcel?.code
          )
        } catch (error) {
          showToastError(error, __(T.error.error), this.isModal)
        }
      }
      this.setState({ items: processedItems }, this.onItemsDecodedCallback)
    }
  }

  onItemsDecodedCallback = () => {
    // extensible
  }

  async removeItem(decodedItem: DecodedItem) {
    const { parcel, items } = this.state
    if (this.isBatchReadingMode) {
      if (!parcel) return
      await this.updateParcel(
        'REMOVE',
        Object.values(decodedItem)
          .map((itm) => itm.item?.id)
          .filter(Boolean) as string[],
        parcel.code
      )
    }
    const newItems = OperationReadingProvider.removeItem(decodedItem, items)
    this.setState({ items: newItems })
  }

  initRemoveModeByRfid = () => {
    if (this.isRemoveModeByRfidRead) {
      RfidReader.clear()
    }
  }

  onConfirm = async () => {
    this.setState({ confirming: true }, this._confirm)
  }

  getConfirmData = () => {
    const parcel = this.state.parcel ?? this.locationState.parcel
    const { formData } = this.state
    const confirmData = getDataFromSchema(formData, this.formSchema)
    if (this.asn && this.asn.code && (!confirmData.asn || !confirmData.asn.code)) {
      if (!confirmData.asn) confirmData.asn = {}
      confirmData.asn.code = this.asn.code
    } else if (parcel && parcel.asn && parcel.asn.code && (!confirmData.asn || !confirmData.asn.code)) {
      if (!confirmData.asn) confirmData.asn = {}
      confirmData.asn.code = parcel.asn.code
    }
    if (confirmData.asn) {
      if (this.operation.type === 'INBOUND' && !confirmData.asn?.destinationPlaceId) {
        confirmData.asn = {
          ...confirmData.asn,
          destinationPlaceId: AppStore.defaultWorkstation?.placeId,
        } as any
      } else if (this.operation.type === 'OUTBOUND' && !confirmData.asn?.originPlaceId) {
        confirmData.asn = {
          ...confirmData.asn,
          originPlaceId: AppStore.defaultWorkstation?.placeId,
        } as any
      } else if (this.operation.type === 'PACKING' && !confirmData.operationPlaceId) {
        confirmData.operationPlaceId = AppStore.defaultWorkstation!.placeId
      }
    }
    return confirmData
  }

  createParcel = async () => {
    let parcel = this.state.parcel ?? this.locationState.parcel
    const { items } = this.state
    const confirmData = this.getConfirmData()
    if (!parcel) {
      parcel = await this.startParcel(confirmData)
    }
    return { parcel, confirmData, items }
  }

  startParcel = async (confirmData) => {
    if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing workstation place')
    const parcel = await Shippings.startParcel(this.operation, {
      operationId: this.operation.id,
      attributes: confirmData.attributes,
      ...confirmData,
      asn: { ...confirmData.asn },
    })
    return parcel
  }

  openRequiredFieldsModal = async (isShowingOnConfirm = false) => {
    try {
      await new Promise((resolve, reject) => {
        AppStore.callbacks.openModalForm(
          resolve,
          this._cancel,
          isShowingOnConfirm ? __UP(T.misc.update_and_confirm) : __UP(T.misc.confirm),
          __UP(T.misc.back)
        )
      })
    } catch (error) {
      this.setState({ confirming: false })
      return
    }
  }

  _cancel = () => {
    this.setState({ confirming: false })
  }

  _confirm = async () => {
    const asyncJobs = hasAsyncConfirm(this.operation)
    try {
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }

      //check if some data of schema is required and undefined: if check is false then open Modal Form
      if (this.operation.showModalOnConfirm || !checkRequiredField(this.state.formData, this.formSchema)) {
        await this.openRequiredFieldsModal(true)
      }

      if (hasChecklist(this.operation) && this.state.parcel && this.state.parcel.checklist) {
        const { validationResult, message } = validateChecklistReadings(
          this.state.items,
          this.operation,
          ShippingExtensions,
          this.state.parcel.checklist,
          this.state.parcel.products
        )
        if (validationResult === 'KO') {
          showToastError(new Error(message), __(T.error.error), this.isModal)
          this.setState({ confirming: false })
          return
        }
        if (validationResult === 'WARNING') {
          const askConfirmResult = await askUserConfirmation(
            __(T.misc.checklist),
            message,
            __(T.misc.no),
            __(T.misc.yes)
          )
          if (!askConfirmResult) {
            this.setState({ confirming: false })
            return
          }
        }
      }

      const parcelResponse = await this.createParcel()
      let parcel: ShippingParcel | undefined = parcelResponse?.parcel
      const confirmData: Record<PropertyKey, any> = parcelResponse?.confirmData ?? {}
      const items = parcelResponse?.items ?? []

      if (!parcel) throw new Error('Missing parcel')

      await ShippingExtensions.beforeConfirm(this.operation, confirmData, items)

      if (!this.isBatchReadingMode) {
        await this.updateParcel('CLEAR', [], parcel.code)
        parcel = await this.updateParcel(
          'INSERT',
          items.filter(filterIgnoredAndErrorItems).flatMap(({ item }) => (item?.id ? item.id : [])),
          parcel.code
        )
      }

      const confirmParcelData: any = {
        operationId: this.operation.id,
        attributes: confirmData.attributes || {},
        ...confirmData,
        parcelCode: parcel.code,
      }
      const result: any = await Shippings.confirmParcel(
        this.operation,
        confirmParcelData,
        false,
        asyncJobs,
        confirmData.asn ? true : false
      )
      if (asyncJobs) {
        if (result && result.asyncExecutionId) {
          this.setState({ lastAsyncID: result.asyncExecutionId })
        } else {
          this._confirmFailed()
        }
      } else {
        this._confirmSuccess(result)
      }
    } catch (err) {
      this.setState({ confirming: false })
      showToastError(err, __(T.error.error), this.isModal)
    }
  }

  _confirmFailed = (message?: string) => {
    showToastError(message && message != '' ? message : __(T.error.operation_confirm_error), __(T.error.error), true)
    this.setState({ confirming: false })
  }

  _confirmSuccess = async (result?) => {
    const confirmData = this.getConfirmData()
    await ShippingExtensions.afterConfirm(this.operation, confirmData, result || {})
    this.setState({ confirming: false })
    showToast({
      title: __(T.misc.success),
      description: __(T.messages.generic_success, { code: this.operation.description }),
      status: 'success',
    })
    if (['hideSuccessModal', 'disabled'].includes(this.operation.postConfirmAction)) {
      this.goBack()
    } else {
      if (
        await askUserConfirmation(
          __(T.confirm.post_confirm_action_title),
          this.operation.postConfirmAction === 'keepInput'
            ? __(T.confirm.post_confirm_action_keep_input)
            : __(T.confirm.post_confirm_action_change_input),
          __(T.misc.no),
          __(T.misc.yes)
        )
      ) {
        if (this.operation.postConfirmAction === 'keepInput') {
          this.onClearKeepInput()
        } else {
          this.goBack()
        }
      } else {
        this.goDashboard()
      }
    }
  }

  goDashboard = () => {
    Router.navigate('/')
  }

  goBack = () => {
    if (this.locationState?.backRoute) {
      Router.navigate(this.locationState?.backRoute, this.locationState?.backRouteParams ?? {})
    } else if (this.formSchema.length) {
      Router.navigate('/shipping/:opCode', { opCode: this.operation.code })
    } else {
      this.goDashboard()
    }
  }

  onClear = async () => {
    const { parcel } = this.state
    if (this.isBatchReadingMode && parcel) {
      await this.updateParcel('CLEAR', [], parcel.code)
    }
    this.setState({ items: [] })
    RfidReader.clear()
  }

  onClearKeepInput = async () => {
    if (this.isBatchReadingMode) {
      const confirmData = this.getConfirmData()
      const parcel = await this.startParcel(confirmData)
      this.setState({ items: [], parcel: parcel })
    } else {
      this.setState({ items: [], parcel: undefined })
    }
    RfidReader.clear()
  }

  showConfirmButton() {
    const { confirming } = this.state

    if (this.canConfirm()) {
      return (
        <Button style={{ marginTop: 15 }} loading={confirming} title={__(T.misc.confirm)} onClick={this.onConfirm} />
      )
    }
    return null
  }

  setFormData = async (fd) => {
    if (!(await ShippingExtensions.formDataIsValid(fd, this.operation, this.formSchema))) return
    this.setState({ formData: fd })
    this.setRfidReaderDecode()
  }

  onRemoveChange: (checked: boolean) => void = (removeModeEnabled) => {
    this.setState({ removeModeEnabled }, this.initRemoveModeByRfid)
  }

  render() {
    const { items, formData, loading, parcel, sseStatus, removeModeEnabled } = this.state

    let tagCounterExpecteds = 0
    let tagCounterUnexpecteds = 0
    if (parcel?.checklist) {
      const { expecteds, unexpecteds, overQty } = getChecklistItemsCount(
        items,
        this.operation,
        ShippingExtensions,
        parcel.checklist,
        parcel.products
      )
      tagCounterExpecteds = expecteds
      tagCounterUnexpecteds = unexpecteds + overQty
    }
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        alertOnBackPress={
          this.operation.showAlertOnExit &&
          !this.isBatchReadingMode &&
          items.filter(filterIgnoredAndErrorItems).length > 0
        }
        loading={loading}
        headerRight={
          <>
            {hasAsyncConfirm(this.operation) ? <Stoplight active={sseStatus} /> : undefined}
            {this.operationRemoveModeAllowed && <RemoveMode onRemoveChange={this.onRemoveChange} />}
          </>
        }
        header={{
          details: {
            data: _cloneDeep(formData),
            formSchema: this.formSchema,
            asn: this.asn,
            setFormData: this.setFormData,
            resetFormData: async (fd) => {
              this.setState({ formData: fd })
            },
            operationId: this.operation.id,
          },
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter
              detected={items.filter(filterIgnoredAndErrorItems).length}
              expected={tagCounterExpecteds}
              unexpected={tagCounterUnexpecteds}
            />
            <AntennaButton
              ref={this.antennaRef}
              decodeRequest={this.getDecodeRequest()}
              onItemDecoded={this.onDecodedItemCallback}
              onClear={this.onClear}
              hideClear={items.length === 0}
            />
            {checkItemsCounterError(items) && (
              <>
                <Spacer />
                <OperationReadingCounters operation={this.operation} items={items} />
              </>
            )}
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <OperationReadingList
            removeItemCallback={this.removeItem}
            extension={ShippingExtensions}
            items={items}
            operation={this.operation}
            checklist={parcel ? parcel.checklist : undefined}
            products={parcel ? parcel.products : undefined}
            removeModeEnabled={removeModeEnabled && this.isRemoveModeBySku}
          />
        </Page.Content>
        {!sseStatus && (
          <FullLoadingLayer
            message={!sseStatus ? __(T.messages.connection_in_progress) : __(T.messages.operation_in_progress)}
          />
        )}
      </Page>
    )
  }
}
