import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Button, Modal } from 'stylewhere/components'
import { TmrKillTag } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  tags: TmrKillTag[]
  visible: boolean
  onConfirm: (target: string) => void
  onClose: () => void
}

interface State {
  identifier: string
}

export class IdentifierListModal extends Component<Props, State> {
  state: State = {
    identifier: '',
  }

  chooseIdentifier = (tag) => {
    const { identifier } = this.state
    if (tag !== identifier && tag.tokill) {
      this.setState({ identifier: tag.code })
    }
  }

  _confirm = () => {
    const { onConfirm } = this.props
    const { identifier } = this.state
    onConfirm(identifier)
  }

  _close = () => {
    this.setState({ identifier: '' }, this.callback)
  }

  callback = () => {
    const { onClose } = this.props
    onClose()
  }

  render() {
    const { tags, visible } = this.props
    const { identifier } = this.state
    const btn1 = { flex: 1, minWidth: 'auto', height: 60 }
    return (
      <Modal visible={visible} onClose={this._close} size="xl">
        <Box>
          <Title>{__(T.misc.confirm_kill_tag)}</Title>
          <Description>{__(T.misc.select_tag_to_kill)}</Description>
        </Box>
        {tags.map((tag, index) => {
          const isselected = identifier === tag.code
          return (
            <IdentfierBox disabled={!tag.tokill} key={'tag_' + index} mt={index > 0 ? 10 : 0} onClick={() => this.chooseIdentifier(tag)}>
              <Radio>{isselected && <RadioSelected />}</Radio>
              <Identifier>{tag.code}</Identifier>
            </IdentfierBox>
          )
        })}
        <Box row mt={30}>
          <Button
            variant="default"
            title={__(T.misc.cancel)}
            onClick={this._close}
            style={{ ...btn1, marginRight: 8 }}
          />
          <Button
            disabled={identifier === ''}
            title={__(T.misc.confirm)}
            onClick={this._confirm}
            style={{ ...btn1, marginLeft: 8 }}
          />
        </Box>
      </Modal>
    )
  }
}

const Title = styled.span`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
`

const Description = styled.label`
  font-weight: 500;
  padding: 25px 15px;
  font-size: 21px;
  text-align: center;
  opacity: 0.5;
`

const IdentfierBox = styled(Box)`
  border: 1px solid #dedede;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Radio = styled(Box)`
  width: 24px;
  height: 24px;
  border: 1px solid #dedede;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RadioSelected = styled(Box)`
  width: 14px;
  height: 14px;
  border-radius: 10px;
  background-color: #3479ff;
`

const Identifier = styled.label`
  flex: 1;
  margin-left: 10px;
  font-weight: 400;
  font-size: 20px;
`
